export const CREATE_TRADER_INIT_VALUES = {
    displayName: '',
    location: '',
    username: '',
    initialUsername: '',
    initialImage: null,
    image: null,
    firstName: '',
    lastName: '',
    addressLine: '',
    additionalAddressLine: '',
    city: '',
    country: '',
    countryIdentityName: '',
    docType: 'ID_CARD',
    number: '',
    passportPhoto: null,
    personPhoto: null,
    postalCode: '',
    region: 'DEFAULT',
    taxIdentityNumber: '',
};
