export var ImageEditStatus;
(function (ImageEditStatus) {
    ImageEditStatus["Deleted"] = "DELETED";
    ImageEditStatus["Updated"] = "UPDATED";
})(ImageEditStatus || (ImageEditStatus = {}));
export var ProfileViewMode;
(function (ProfileViewMode) {
    ProfileViewMode["View"] = "VIEW";
    ProfileViewMode["Edit"] = "EDIT";
})(ProfileViewMode || (ProfileViewMode = {}));
