import { createSlice } from '@reduxjs/toolkit';
import { userApi } from '@shared/api/current-user';
import { profileApi } from '@shared/api/profile';
import { ProfileViewMode, } from '../model';
import { getTraderValues, mapDataToProfile, mapDataToProfileValues, mapProfileDataAfterUpdate, sortByPopularCountries, } from '../lib';
import { POPULAR_COUNTRIES_LIST } from './constants';
const SliceName = 'profile';
const initialState = {
    currentUserProfile: undefined,
    profile: null,
    viewMode: ProfileViewMode.View,
    profileFormValues: null,
    createTraderProfileValues: null,
    createTraderCurrentStep: null,
    createTraderPrevStep: null,
    socialNetworks: null,
};
const profileSlice = createSlice({
    name: SliceName,
    initialState,
    reducers: {
        editProfileInfo: (state) => {
            state.viewMode = ProfileViewMode.Edit;
        },
        cancelProfileEdit: (state) => {
            state.viewMode = ProfileViewMode.View;
        },
        openCreateTraderForm: (state) => {
            if (state.currentUserProfile) {
                const { values } = getTraderValues(state.currentUserProfile);
                state.createTraderProfileValues = values;
                state.createTraderCurrentStep = 0;
                state.createTraderPrevStep = null;
            }
        },
        setCreateTraderStep: (state, { payload }) => {
            state.createTraderPrevStep = state.createTraderCurrentStep;
            state.createTraderCurrentStep = payload;
        },
        updateCreateTraderValues: (state, { payload }) => {
            state.createTraderProfileValues = Object.assign(Object.assign({}, state.createTraderProfileValues), payload);
        },
        setPassedVerification: (state) => {
            state.isVerificationPassed = true;
        },
        closeCreateTraderForm: (state) => {
            state.createTraderProfileValues = null;
            state.createTraderPrevStep = null;
            state.createTraderCurrentStep = null;
            state.areTraderValuesPrefilled = false;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(profileApi.endpoints.updateProfile.matchFulfilled, (state, { payload }) => {
            state.currentUserProfile = payload;
            state.profileFormValues = mapProfileDataAfterUpdate(payload);
        });
        builder.addMatcher(userApi.endpoints.getMe.matchFulfilled, (state, { payload }) => {
            var _a;
            state.profile = mapDataToProfile(payload);
            state.profileFormValues = mapDataToProfileValues(payload); // TODO: pass profile
            state.currentUserProfile = mapDataToProfile(payload);
            state.canSwitchProfile = ((_a = payload.profiles) === null || _a === void 0 ? void 0 : _a.length) > 1;
        });
        builder.addMatcher(profileApi.endpoints.createProfile.matchFulfilled, (state) => {
            state.canSwitchProfile = true;
        });
        builder.addMatcher(profileApi.endpoints.getProfileById.matchFulfilled, (state, { payload }) => {
            state.profile = payload;
        });
        builder.addMatcher(profileApi.endpoints.getSocialNetworks.matchFulfilled, (state, { payload }) => {
            state.socialNetworks = payload;
        });
        builder.addMatcher(profileApi.endpoints.getCountriesList.matchFulfilled, (state, { payload }) => {
            const { otherCountries, popularCountries } = sortByPopularCountries(payload, POPULAR_COUNTRIES_LIST);
            state.countriesList = otherCountries;
            state.popularCountriesList = popularCountries;
        });
    },
});
export { SliceName };
export const { cancelProfileEdit, editProfileInfo, reset, setCreateTraderStep, openCreateTraderForm, closeCreateTraderForm, updateCreateTraderValues, setPassedVerification, } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
