import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useLazySearchByNicknameQuery } from '@shared/api/profile';
import { IsDefined } from '@utils/js-ts';
var SearchProfileErrorCode;
(function (SearchProfileErrorCode) {
    SearchProfileErrorCode["NOT_FOUND"] = "profile.profile_not_found";
})(SearchProfileErrorCode || (SearchProfileErrorCode = {}));
const useNicknameCreation = ({ trigger, getValues, setError, skipEmptyValue = false, }) => {
    const [findByNickname, findByNicknameState] = useLazySearchByNicknameQuery({});
    const { t } = useTranslation();
    const nicknameInputStatus = useMemo(() => {
        var _a, _b;
        if (skipEmptyValue === false) {
            trigger('username');
        }
        if (findByNicknameState.isFetching) {
            return { status: 'loading' };
        }
        if (findByNicknameState.isSuccess) {
            if (((_a = findByNicknameState.originalArgs) === null || _a === void 0 ? void 0 : _a.nickname) === getValues('initialUsername')) {
                return {
                    status: 'success',
                };
            }
            // this nickname is not our and not free
            if (IsDefined(findByNicknameState === null || findByNicknameState === void 0 ? void 0 : findByNicknameState.currentData)) {
                setError('username', { message: t('profile.messages.nickname_non_unique') });
                return { status: 'error' };
            }
            return { status: 'success' };
        }
        if (findByNicknameState.isError) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line max-len
            const isNewNickname = ((_b = findByNicknameState.error) === null || _b === void 0 ? void 0 : _b.code) === SearchProfileErrorCode.NOT_FOUND;
            if (isNewNickname) {
                return {
                    status: 'success',
                    text: t('profile.messages.nickname_available'),
                };
            }
            return { status: 'error' };
        }
        return { status: 'idle' };
    }, [findByNicknameState]);
    const nicknameOnValidate = useCallback(() => (event) => {
        findByNickname({ nickname: event.target.value });
    }, [findByNickname]);
    return {
        nicknameInputStatus,
        findByNicknameState,
        findByNickname,
        nicknameOnValidate,
    };
};
export default useNicknameCreation;
