import { useAppSelector } from '@app/store/Hooks';
import { useGetProfileByNicknameQuery } from '@shared/api/profile';
const useProfileInfo = (profileNickname) => {
    const { currentUserProfile, profileFormValues } = useAppSelector(state => state.profile);
    const { data, isFetching: isLoadingProfile, } = useGetProfileByNicknameQuery({
        nickname: profileNickname,
    }, { skip: typeof profileNickname === 'undefined' });
    return {
        isUserProfileOwner: profileNickname === (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.username),
        profile: data,
        isFetching: isLoadingProfile,
        formInitialValues: profileFormValues,
    };
};
export default useProfileInfo;
