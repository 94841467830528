import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronIcon } from '@icons/wolfkit-solid/chevron-small-down-solid.svg';
import Icon from '@shared/ui/icons/Icon';
import { ContainerRow } from '@components/styled';
import { BodySemiBold } from '@components/styled/Typography';
const Container = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: props.theme.palette.primary.main,
    gap: `${props.theme.spacing_sizes.s}px`,
}));
const ViewMoreToggleIcon = styled.div(props => ({
    width: '16px',
    height: '16px',
    transform: `scaleY(${props.flip ? -1 : 1})`,
}));
const ViewMoreToggle = ({ isViewMoreActive = false, onClick = () => { }, }) => {
    const { t } = useTranslation();
    return (_jsxs(Container, { onClick: onClick, children: [_jsx(BodySemiBold, { lineHeight: '22px', children: isViewMoreActive ?
                    t('profile.view_less') :
                    t('profile.view_more') }), _jsx(ViewMoreToggleIcon, { flip: isViewMoreActive, children: _jsx(Icon, { size: 16, IconComponent: ChevronIcon }) })] }));
};
export default ViewMoreToggle;
